@charset "utf-8";

.site-tag {
  margin-right: 1%;
  margin-left: 1%;
}

.author__portrait {
 border-radius: 20%;
  filter: drop-shadow(15px 15px 20px black);
}

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials